.arrow {
    width: 15px;
    // height: 15px;
}

.player-element-container {
    font-family: "Helvetica Neue";
    height: 80px;
    width: 500px;
    box-shadow: 0 25px 50px 10px rgb(0 0 0 / 5%);
    background-color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
}
@media only screen and (max-width: 1200px) {
    .player-element-container {
        width: 450px;
    }
}
@media only screen and (max-width: 1024px) {
    .player-element-container {
        display: flex;
        flex-flow: column;
        height: 200px;
        width: 250px;
        padding: 20px;
        justify-content: inherit !important;
        padding: 0 !important;
    }

    .arrow {
        margin: auto !important;
    }

    .player-number,
    .player-name {
        text-align: center;
    }

    .player-element-container {
        .player-number {
            order: 1;
        }
        .player-name {
            order: 2;
        }
        .arrow {
            order: 3;
        }
    }
    .buttons-previous-next {
        margin-bottom: 150px;
    }
    .player-element-container {
        .player-name {
            font-size: 30px;
            letter-spacing: -0.5px;
        }
        .player-number {
            font-size: 30px;
            letter-spacing: -0.5px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .player-element-container {
        .player-name,
        .player-number {
            font-size: 28px;
        }
    }
    .player-element-container {
        height: 160px;
    }
}

@media only screen and (max-width: 660px) {
    .player-element-container {
        .player-name,
        .player-number {
            font-size: 26px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .player-element-container {
        display: flex;
        height: 100px;
        width: 150px;
        padding: 0;
        font-size: 18px;
    }

    .arrow {
        margin: 0 !important;
    }

    .player-number,
    .player-name {
        text-align: center;
    }

    .player-number {
        font-size: 15px;
        order: 1;
    }
    .player-name {
        font-size: 15px;
        order: 2;
    }
    .arrow {
        order: 3;
    }

    .buttons-previous-next {
        margin-bottom: 75px;
    }

    .player-element-container {
        .player-name,
        .player-number {
            font-size: 16px;
        }
        .player-name {
            padding: 0 5px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .player-element-container {
        width: 100px;
        font-size: 8px;
        gap: 5px;
    }
}
