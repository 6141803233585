$main-color: #fbeb24;
$font-family: "Helvetica Neue";

@media only screen and (min-width: 1200px) {
  .news-section {
    font-family: $font-family;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 110px;

    .news-section-title {
      font-size: 75px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 80px;
    }

    .news-section-news {
      display: flex;
      justify-content: space-between;
    }
  }

  .news-element-container {
    height: 650px;
    width: 410px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    position: relative;
    margin-top: 50px;
    margin-bottom: 100px;

    .image-container {
      height: 300px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .article-tag {
        font-size: 16px;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 45px;
        background-color: white;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;

        .article-tag-img {
          height: 12px;
          width: 12px;
          margin-right: 10px;
        }
      }
    }

    .content-container {
      padding-top: 55px;
      padding-left: 45px;
      padding-right: 45px;

      .news-title {
        font-size: 35px;
        margin-bottom: 20px;
        line-height: 40px;
        height: 80px;
        overflow: hidden;
      }

      .news-description {
        font-size: 16px;
        line-height: 22px;
        height: 66px;
        overflow: hidden;
      }
    }

    .discover-more-button {
      text-align: end;
      position: absolute;
      bottom: -40px;
      right: 0;
    }
  }
  .news-carousel-buttons-desktop {
    .prev-button-desktop-news {
      background: black;
      height: 75px;
      width: 75px;
      position: absolute;
      top: 50%;
      left: -100px;
      cursor: pointer;

      .prev-button-img {
        height: 20px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }
    .next-button-desktop-news {
      background: black;
      height: 75px;
      width: 75px;
      position: absolute;
      top: 50%;
      right: -100px;
      cursor: pointer;

      .next-button-img {
        height: 20px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }
  }
  .news-carousel-buttons-mobile {
    display: none;
  }
  .news-carousel-container {
    position: relative;
  }
}

@media only screen and (max-width: 1200px) {
  .news-section {
    font-family: $font-family;
    margin-bottom: 150px;

    .news-section-title {
      font-size: 45px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .news-section-news {
      display: flex;
      width: 100%;
      overflow-x: scroll;
    }

    .slick-slide.slick-active.slick-current {
      width: calc(70vw + 35px) !important;
      margin-left: 5px;
    }
  }

  .news-element-container {
    height: 381px;
    // width: 70vw;
    width: 320px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 20px;
    margin-bottom: 60px;

    .image-container {
      height: 180px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .article-tag {
        position: absolute;
        left: 0;
        bottom: -1px;
        height: 25px;
        background-color: white;
        font-size: 10px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;

        .article-tag-img {
          height: 10px;
          width: 10px;
          margin-right: 10px;
        }
      }
    }

    .content-container {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;

      .news-title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 20px;
        height: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .news-description {
        font-size: 12px;
        line-height: 17px;
        height: 51px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .discover-more-button {
      text-align: end;
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }

  .news-carousel-container {
    .news-carousel-buttons-mobile {
      display: flex;
      justify-content: center;
      .prev-button-mobile-news {
        height: 50px;
        width: 50px;
        margin-left: 5px;
        margin-right: 5px;

        position: relative;

        .prev-button-img {
          height: 10px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }

      .next-button-mobile-news {
        height: 50px;
        width: 50px;
        margin-left: 5px;
        margin-right: 5px;

        position: relative;

        .next-button-img {
          height: 10px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }
    }
    .news-carousel-buttons-desktop {
      display: none;
    }
  }

  #root
    > div
    > div:nth-child(2)
    > div:nth-child(2)
    > div
    > div.news-carousel-container
    > div.alice-carousel
    > div
    > div
    > ul
    > li.alice-carousel__stage-item.__active {
    // width: 80vw !important;
  }
}

#root
  > div
  > div:nth-child(2)
  > div.news-section
  > div.react-multi-carousel-list {
  overflow: visible;
}
