@media only screen and (min-width: 1024px) {
  .groups-page-container {
    font-family: "Helvetica Neue";
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 132px;

    .groups-page-title {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 70px;
    }

    .groups-list {
      display: grid;
      justify-content: center;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, 324px);
    }
  }

  .group-element-individual {
    height: 311px;
    width: 324px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    position: relative;
    padding-top: 40px;
    padding-left: 40px;
    margin-bottom: 65px;

    .group-name {
      font-size: 45px;
      margin-bottom: 30px;
      line-height: 48px;
      // height: 96px;
      padding-right: 30px;
    }

    .group-coach {
      display: flex;
      .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: cover;
      }

      .coach {
        font-size: 16px;
        font-weight: 800;
      }

      .coach-name {
        font-size: 14px;
      }
    }

    .discover-more-button {
      text-align: end;
      position: absolute;
      bottom: -40px;
      right: 0;
    }
  }

  .loader-container {
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .groups-page-container {
    font-family: "Helvetica Neue";
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .groups-page-title {
      font-size: 45px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 75px;
    }

    .groups-list {
      display: grid;
      justify-content: center;
      grid-gap: 15px;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .group-element-individual {
    height: 169px;
    width: 100%;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    position: relative;
    padding-top: 40px;
    padding-left: 40px;
    margin-bottom: 30px;

    .group-name {
      font-size: 30px;
      margin-bottom: 15px;
      line-height: 27px;
    }

    .group-coach {
      display: flex;
      .avatar {
        vertical-align: middle;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: cover;
      }

      .coach {
        font-size: 12px;
        font-weight: 800;
      }

      .coach-name {
        font-size: 10px;
      }
    }

    .discover-more-button {
      text-align: end;
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }

  .loader-container {
    text-align: center;
  }
}
