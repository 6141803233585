.setup-form-container-donations {
  // margin-top: 50px;
  margin-bottom: 50px;
}

.form-buttons {
  display: inline-flex;
  gap: 30px;
  margin-top: 20px;
}

@media only screen and (min-width: 1200px) {
  .form-buttons {
    margin-top: 60px;
  }

  .form-part-donations {
    // margin-top: 100px;
    padding-bottom: 60px;
  }
}
