@media only screen and (min-width: 1024px){
    .onlinepayments-page-container {
        margin-top: 100px;
        //margin-bottom: 170px;
        // display: flex;
        justify-content: center;
        font-family: "Helvetica Neue";
    }
}

@media only screen and (max-width: 1024px) {
    .onlinepayments-page-container {
      margin-top: 50px;
      margin-bottom: 75px;
      font-family: "Helvetica Neue";
    }
}

@media only screen and (min-width: 768px) {
    .onlinepayments-page-container {
      font-family: "Helvetica Neue";
      // padding-left: 50px;
      // padding-right: 50px;
      padding-bottom: 132px;
      .onlinepayments-title {
        font-size: 100px;
        text-align: center;
        font-weight: 700;
        padding-bottom: 70px;
      }
    }
}

@media only screen and (max-width: 768px) {
    .onlinepayments-page-container {
      font-family: "Helvetica Neue";
      // padding-left: 20px;
      // padding-right: 20px;
      // padding-bottom: 70px;
  
      .onlinepayments-title {
        font-size: 45px;
        text-align: center;
        font-weight: 700;
        padding-bottom: 15px;
      }
    }
}  