@media only screen and (min-width: 1200px) {
  .custom-button-3 {
    font-family: "Montserrat Bold";
    padding: 10px 20px;
    font-size: 16px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .custom-button-3 {
    font-family: "Montserrat Bold";
    padding: 10px 15px;
    font-size: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
