@media only screen and (min-width: 1200px) {
  .onlinepayments-success-message {
    font-family: "Helvetica Neue";
    padding-left: 95px;
    padding-right: 95px;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 100px 0 50px 0;
  }
  .donations-success-message {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    // padding-bottom: 30px;
    margin: 100px 0px;
    margin-bottom: 50px;
  }
  
  .button-row-success-payment {
    margin-bottom: 100px;
  }
  .another-donation-row {
    margin-bottom: 100px;
    // justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .onlinepayments-success-message {
    font-family: "Helvetica Neue";
    font-size: 16px;
    // font-weight: 700;
    text-align: center;
    // padding-bottom: 30px;
    margin: 30px 0px;
  }

  .button-row-success-payment {
    margin-bottom: 50px;
  }

  .donations-success-message {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    // padding-bottom: 30px;
    margin: 50px 0px;
    margin-bottom: 30px;
  }
  .another-donation-row {
    margin-bottom: 50px;
    justify-content: center;
  }
}
