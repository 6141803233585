@media only screen and (min-width: 1024px) {
  .staff-member-page-container-3 {
    margin-top: 100px;
    margin-bottom: 170px;
    display: flex;
    justify-content: center;
    font-family: "Montserrat Bold";

    .staff-image {
      width: 585px;
      margin-right: 150px;

      img {
        width: 100%;
        height: 585px;
        object-fit: cover;
      }
    }

    .staff-details {
      width: 585px;
      .staff-name {
        font-family: "Montserrat BlackItalic";
        font-size: 75px;
        line-height: 80px;
        font-weight: 700;
        margin-bottom: 50px;
      }

      .staff-role {
        font-size: 35px;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 100px;
      }

      .staff-description {
        font-family: "Montserrat Medium";
        font-style: italic;
        font-size: 20px;
        line-height: 35px;
        color: #808080;
      }
    }
  }

  .staff-member-page-slider-title-3 {
    font-size: 75px;
    font-weight: 700;
    color: black;
    text-align: center;
    font-family: "Montserrat Bold";
    margin-bottom: 70px;
  }

  .other-staff-carousel-container-not-enough-el-3 {
    margin-bottom: 115px;
    display: flex;
    justify-content: space-around;
  }

  .other-staff-carousel-container-3 {
    position: relative;
    margin-bottom: 115px;
    padding-left: 50px;
    padding-right: 50px;
    .other-staff-carousel-buttons {
      display: flex;
      justify-content: space-between;
      bottom: 50%;
      position: absolute;
      left: -50px;
      right: -50px;

      .prev-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .prev-button-img {
          height: 15px;
        }
      }

      .next-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .next-button-img {
          height: 15px;
        }
      }
    }
    .other-staff-carousel-buttons-mobile {
      display: none;
    }
  }

  .staff-member-slider-card-3 {
    height: 525px;
    width: 325px;
    font-family: "Montserrat Bold";
    position: relative;
    margin-bottom: 125px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;

    .staff-member-image-container {
      height: 400px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
    }

    .staff-member-name {
      text-align: center;
      margin-top: 10px;
      font-size: 20px;
      line-height: 38px;
    }

    .staff-member-label {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    .see-more-button-staff-3 {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: -13px;

      .arrow-button-3 {
        font-family: "Montserrat SemiBold";
        padding: 5px 45px;
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .staff-member-page-container-3 {
    margin-top: 50px;
    margin-bottom: 75px;
    font-family: "Montserrat Bold";

    .staff-image {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 40px;

      img {
        width: 100%;
        height: calc(100vw - 80px);
        object-fit: cover;
      }
    }

    .staff-details {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      .staff-name {
        font-family: "Montserrat BlackItalic";
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .staff-role {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 45px;
      }

      .staff-description {
        font-family: "Montserrat Medium";
        font-style: italic;
        font-size: 15px;
        line-height: 25px;
        color: #808080;
      }
    }
  }

  .staff-member-page-slider-title-3 {
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    font-family: "Montserrat Bold";
    padding-left: 20px;
    padding-right: 20px;
  }

  .other-staff-carousel-container-3 {
    position: relative;
    margin-bottom: 150px;
    .other-staff-carousel-buttons {
      display: none;
    }
    .other-staff-carousel-buttons-mobile {
      display: flex;
      justify-content: center;
      .prev-button-desktop-teams {
        height: 50px;
        width: 50px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .prev-button-img {
          height: 10px;
        }
      }

      .next-button-desktop-teams {
        height: 50px;
        width: 50px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .next-button-img {
          height: 10px;
        }
      }
    }
  }

  .staff-member-slider-card-3 {
    height: 390px;
    width: 70vw;
    font-family: "Montserrat Bold";
    position: relative;
    margin-bottom: 80px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;

    .staff-member-image-container {
      margin-bottom: 10px;
      position: relative;
      height: 265px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .staff-member-name {
      text-align: center;
      font-size: 16px;
      line-height: 27px;
    }

    .staff-member-label {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 11px;
    }

    .see-more-button-staff-3 {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: -35px;

      .arrow-button-3 {
        font-family: "Montserrat SemiBold";
        padding: 5px 20px;
        font-size: 16px;
      }
    }
  }

  #root
    > div
    > div.other-staff-carousel-container
    > div.alice-carousel
    > div
    > div
    > ul
    > li.alice-carousel__stage-item.__active {
    width: 80vw !important;
  }
}
