@media only screen and (min-width: 1024px) {
  .about-container {
    font-family: "Helvetica Neue";
    // padding-left: 250px;
    // padding-right: 250px;
    padding-left: 10%;
    padding-right: 10%;

    .about-photo {
      margin-bottom: 100px;

      img {
        max-height: 575px;
        width: 100%;
        object-fit: cover;
      }
    }

    .about-title {
      font-size: 100px;
      line-height: 100px;
      color: black;
      font-weight: 700;
      margin-bottom: 75px;
      text-align: center;
    }

    .about-text-container {
      // padding-left: 300px;
      // padding-right: 300px;
      padding-left: 15%;
      padding-right: 15%;
      padding-bottom: 170px;
      .about-subtitle {
        font-size: 35px;
        color: black;
        font-weight: 700;
        margin-bottom: 40px;
        line-height: 40px;
      }

      .about-paragraph {
        margin-bottom: 45px;
        font-size: 20px;
        line-height: 35px;
      }

      .about-text-photo {
        margin-bottom: 75px;

        img {
          width: 100%;
        }
      }

      .about-highlighted-text {
        border-left: solid 1px #fbeb24;
        padding-left: 30px;
        font-size: 22px;
        line-height: 1.591;
        margin-bottom: 45px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .about-container {
    font-family: "Helvetica Neue";

    .about-photo {
      margin-bottom: 19px;

      img {
        height: 235px;
        width: 100%;
        object-fit: cover;
      }
    }

    .about-title {
      font-size: 45px;
      color: black;
      font-weight: 700;
      padding-bottom: 30px;
      text-align: center;
    }

    .about-text-container {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 70px;
      .about-subtitle {
        font-size: 24px;
        color: black;
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 24px;
      }

      .about-paragraph {
        margin-bottom: 30px;
        color: #808080;
        font-size: 15px;
        line-height: 25px;
      }

      .about-text-photo {
        margin-bottom: 40px;

        img {
          width: 100%;
        }
      }

      .about-highlighted-text {
        border-left: solid 1px #fbeb24;
        padding-left: 20px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
  }
}
