$main-color: #fbeb24;
$font-family: "Montserrat Bold";

@media only screen and (min-width: 1200px) {
  .groups-section-3 {
    font-family: $font-family;
    margin-bottom: 95px;
    padding-left: 40px;
    padding-right: 40px;
    .groups-section-title {
      font-size: 75px;
      text-align: center;
      margin-bottom: 100px;
    }

    .groups-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .group-element-container-3 {
    height: 400px;
    width: 415px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding-top: 10px;
    margin-top: 50px;
    margin-bottom: 100px;
    border: 2px solid;

    .group-name {
      font-size: 35px;
      margin-bottom: 35px;
      line-height: 60px;
      // height: 120px;
      margin-top: 15px;
      text-align: center;
    }

    .group-coach {
      display: flex;
      justify-content: center;
      text-align: center;

      .coach {
        justify-self: center;
        font-size: 20px;
        font-weight: 800;
      }

      .coach-name {
        font-size: 16px;
      }
    }

    .discover-more-button {
      position: absolute;
      width: 100%;
      bottom: 0;

      .arrow-button {
        font-family: "Montserrat Bold";
        font-size: 25px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 0 0 25px 25px;
        width: 100%;
      }
    }
  }

  .groups-homepage-carousel-container-3 {
    position: relative;
    margin-bottom: 115px;

    .groups-homepage-carousel-buttons {
      display: flex;
      justify-content: space-between;
      bottom: 50%;
      position: absolute;
      left: -90px;
      right: -90px;

      .prev-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .prev-button-img {
          height: 15px;
        }
      }

      .next-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .next-button-img {
          height: 15px;
        }
      }
    }
    .groups-homepage-carousel-buttons-mobile {
      display: none;
    }
  }

  .groups-homepage-carousel-container-not-enough-el-3 {
    margin-bottom: 115px;
    display: flex;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1200px) {
  .groups-section-3 {
    font-family: $font-family;
    margin-bottom: 75;
    .groups-section-title {
      font-size: 45px;
      text-align: center;
      margin-bottom: 20px;
    }

    .groups-container {
      display: flex;
      justify-content: space-between;
    }

    .slick-slide.slick-active.slick-current {
      width: calc(70vw + 35px) !important;
      margin-left: 5px;
    }
  }

  .group-element-container-3 {
    height: 300px;
    width: 320px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 70px;
    border: 2px solid;

    .group-name {
      font-size: 20px;
      line-height: 40px;
      height: 80px;
      text-align: center;
    }

    .group-coach {
      text-align: center;
      display: flex;
      justify-content: center;

      .coach {
        font-size: 15px;
      }

      .coach-name {
        font-size: 12px;
      }
    }

    .discover-more-button {
      position: absolute;
      width: 100%;
      bottom: 0;

      .arrow-button {
        font-family: "Montserrat Bold";
        font-size: 20px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-radius: 0 0 25px 25px;
        width: 100%;
      }
    }
  }

  .no-bg {
    background-color: transparent;
  }

  .groups-homepage-carousel-container-3 {
    position: relative;
    margin-bottom: 30px;

    .groups-homepage-carousel-buttons {
      display: none;
    }
    .groups-homepage-carousel-buttons-mobile {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .prev-button-desktop-teams {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .prev-button-img {
          height: 10px;
        }
      }

      .next-button-desktop-teams {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .next-button-img {
          height: 10px;
        }
      }
    }
  }

  #root
    > div
    > div:nth-child(2)
    > div.groups-section
    > div.groups-homepage-carousel-container
    > div.alice-carousel
    > div
    > div
    > ul
    > li.alice-carousel__stage-item.__active {
    // width: 80vw !important;
  }
}
