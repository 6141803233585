$main-color: #fbeb24;
$font-family: "Helvetica Neue";

@media only screen and (min-width: 1200px) {
  .photos-section {
    font-family: $font-family;
    margin-bottom: 150px;
    .photos-section-title {
      text-transform: uppercase;
      font-size: 75px;
      text-align: center;
      margin-bottom: 100px;
    }

    .photos-grid-container {
      display: flex;
      height: 850px;
      margin-bottom: 50px;
      .grid-column-left {
        width: 50%;
        margin-right: 25px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover .expand-photo-button {
          display: block;
        }

        &:hover img {
          filter: brightness(60%);
          cursor: pointer;
        }
      }

      .grid-column-right {
        width: 50%;

        .grid-row-1 {
          display: flex;
          padding-bottom: 25px;
          position: relative;
          height: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:hover .expand-photo-button {
            display: block;
          }

          &:hover img {
            filter: brightness(60%);
            cursor: pointer;
          }
        }

        .grid-row-2 {
          display: flex;
          height: 50%;

          .grid-column-right-1 {
            width: 50%;
            margin-right: 25px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:hover .expand-photo-button {
              display: block;
            }

            &:hover img {
              filter: brightness(60%);
              cursor: pointer;
            }
          }
          .grid-column-right-2 {
            width: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:hover .expand-photo-button {
              display: block;
            }

            &:hover img {
              filter: brightness(60%);
              cursor: pointer;
            }
          }
        }
      }

      .expand-photo-button {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: none;
        cursor: pointer;

        .expand-photo-button-img {
          height: 25px !important;
          width: 25px !important;
          right: 0;
          top: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }

    .photos-button-row {
      text-align: -webkit-center;
      .see-all-photos-icon {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
  }

  .hide-desktop {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .photos-section {
    font-family: $font-family;
    margin-bottom: 75px;
    .photos-section-title {
      text-transform: uppercase;
      font-size: 45px;
      text-align: center;
      margin-bottom: 40px;
    }

    .photos-grid-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;

      .grid-column-left {
        width: 100%;
        position: relative;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .grid-column-right {
        width: 100%;

        .grid-row-1 {
          display: flex;
          margin-bottom: 10px;
          height: 50%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .grid-row-2 {
          display: flex;
          height: 50%;

          .grid-column-right-1 {
            width: 50%;
            margin-right: 10px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .grid-column-right-2 {
            width: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .photos-button-row {
      text-align: -webkit-center;
      .see-all-photos-icon {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
  }

  .expand-photo-button {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 10px;
    bottom: 10px;

    .expand-photo-button-img {
      height: 15px !important;
      width: 15px !important;
      right: 0;
      top: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}
