@media only screen and (min-width: 1024px) {
  .groups-page-container-3 {
    font-family: "Montserrat Bold";
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 132px;

    .groups-page-title {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 70px;
    }

    .groups-list {
      display: grid;
      justify-content: center;
      grid-gap: 120px;
      grid-template-columns: repeat(auto-fill, 324px);
    }
  }

  .group-element-individual-3 {
    height: 400px;
    width: 415px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    // background-color: white;
    position: relative;
    padding-top: 40px;
    // padding-left: 40px;
    margin-bottom: 65px;
    border: 2px solid;

    .group-name {
      font-size: 35px;
      margin-bottom: 30px;
      line-height: 60px;
      // height: 96px;
      text-align: center;
    }

    .group-coach {
      display: flex;
      justify-content: center;
      text-align: center;

      .coach {
        justify-self: center;
        font-size: 16px;
        font-weight: 800;
      }

      .coach-name {
        font-size: 16px;
      }
    }

    .discover-more-button-3 {
      position: absolute;
      width: 100%;
      bottom: 0;

      .arrow-button-3 {
        font-family: "Montserrat Bold";
        font-size: 25px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 0 0 25px 25px;
        width: 100%;
      }
    }
  }

  .loader-container-3 {
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .groups-page-container-3 {
    font-family: "Montserrat Bold";
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .groups-page-title {
      font-size: 45px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 75px;
    }

    .groups-list {
      display: grid;
      justify-content: center;
      grid-gap: 15px;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .group-element-individual-3 {
    height: 300px;
    width: 100%;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding-top: 20px;
    margin-bottom: 30px;
    border: 2px solid;

    .group-name {
      font-size: 20px;
      margin-bottom: 15px;
      line-height: 40px;
      height: 80px;
      text-align: center;
    }

    .group-coach {
      display: flex;
      justify-content: center;
      text-align: center;

      .coach {
        font-size: 15px;
      }

      .coach-name {
        font-size: 12px;
      }
    }

    .discover-more-button-3 {
      position: absolute;
      width: 100%;
      bottom: 0;

      .arrow-button-3 {
        font-family: "Montserrat Bold";
        font-size: 20px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-radius: 0 0 25px 25px;
        width: 100%;
      }
    }
  }

  .loader-container-3 {
    text-align: center;
  }
}
