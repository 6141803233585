@media only screen and (min-width: 1200px) {
  .contact-container {
    font-family: "Helvetica Neue";
    padding-left: 95px;
    padding-right: 95px;
    .contact-title {
      font-size: 75px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 90px;
    }

    .contact-content {
      display: flex;
      .contact-form-container {
        width: 75%;

        .form-content {
          margin-bottom: 55px;
          .form-row {
            display: flex;
            justify-content: space-between;
            padding-right: 100px;
          }

          .button-row {
            padding-right: 100px;
            display: flex;
            justify-content: flex-end;
          }

          .error-row {
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }

      .contact-details-container {
        width: 25%;
        font-size: 16px;

        .contact-details-icon {
          height: 20px;
          padding-right: 20px;
        }

        .contact-details-content {
          margin-top: 70px;
          .address {
            display: flex;
            align-items: center;
            padding-bottom: 35px;
          }
          .phone {
            display: flex;
            align-items: center;
            padding-bottom: 35px;
          }
          .mail {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .section-title {
      font-size: 35px;
    }
    .send-icon {
      width: 15px;
      height: 15px;
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .contact-container {
    font-family: "Helvetica Neue";
    .contact-title {
      font-size: 45px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 45px;
    }

    .contact-content {
      .contact-form-container {
        width: 100%;

        .form-content {
          margin-bottom: 55px;
          .form-row {
            display: flex;
            flex-direction: column;
            padding-left: 35px;
            padding-right: 35px;
          }

          .button-row {
            text-align: -webkit-center;
          }

          .error-row {
            text-align: center;
            margin-bottom: 15px;
            font-size: 13px;
          }
        }
      }

      .contact-details-container {
        width: 100%;
        font-size: 12px;

        .contact-details-icon {
          height: 15px;
          width: 15px;
          margin-bottom: 10px;
        }

        .contact-details-content {
          margin-top: 25px;
          margin-bottom: 70px;
          .address {
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;
            align-items: center;
          }
          .phone {
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;
            align-items: center;
          }
          .mail {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .send-icon {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }

    .section-title {
      font-size: 24px;
      text-align: center;
    }
  }
}

.confirm-popup-container {
  .confirm-popup-row {
    font-weight: 700;
    text-align: center;
  }

  .confirm-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .confirm-popup-title {
      font-size: 25px;
      font-weight: 700;
    }
  }
  padding-bottom: 30px;
}
