$main-color: #fbeb24;

@media only screen and (min-width: 720px) {
  .container-homepage-3 {
    font-family: "Montserrat Bold";
    margin-bottom: 140px;
    position: relative;
    margin: 0 -50px;
    img {
      width: 100%;
      height: 790px;
      object-fit: cover;
      filter: brightness(0.7);
    }
    .intro-content-container {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      background-color: rgba(9, 16, 35, 0.4);
      padding: 35px;

      .title {
        text-transform: uppercase;
        font-size: 100px;
        line-height: 100px;
        color: white;
        padding-bottom: 45px;
      }

      .description {
        font-size: 20px;
        line-height: 30px;
        color: white;
        padding-bottom: 45px;
      }
    }

    .more-button {
      width: 60px;
      height: 60px;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      .more-button-img {
        height: 15px;
        width: 15px;
        display: block;
        margin: auto;
        margin-top: 22px;
      }
    }
  }

  .button-row-3 {
    text-align: -webkit-center;
  }

  .discover-more-button-icon-3 {
    width: 12px !important;
    height: 12px !important;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 720px) {
  .container-homepage-3 {
    font-family: "Helvetica Neue";
    margin-bottom: 70px;
    position: relative;
    margin: 0 -20px;
    img {
      width: 100%;
      height: 520px;
      object-fit: cover;
    }
    .intro-content-container {
      position: absolute;
      left: 50%;
      top: 47%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 75%;

      .title {
        text-transform: uppercase;
        font-size: 45px;
        line-height: 50px;
        color: white;
        padding-bottom: 15px;
      }

      .description {
        font-size: 14px;
        line-height: 20px;
        color: white;
        padding-bottom: 25px;
      }
    }

    .more-button {
      width: 35px;
      height: 35px;
      background: $main-color;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      .more-button-img {
        height: 6px;
        width: 8px;
        display: block;
        margin: auto;
        margin-top: 15px;
      }
    }
  }
  .button-row-3 {
    text-align: -webkit-center;
  }
  .discover-more-button-icon-3 {
    width: 12px !important;
    height: 12px !important;
    margin-left: 10px;
  }
}
