@media only screen and (min-width: 1024px) {
  .news-individual-page {
    font-family: "Helvetica Neue";
    .article-container {
      // padding-left: 250px;
      // padding-right: 250px;

      .article-photo {
        margin-bottom: 100px;

        img {
          max-height: 575px;
          width: 100%;
          object-fit: cover;
        }
      }

      .article-title {
        font-size: 75px;
        color: black;
        font-weight: 700;
        line-height: 1.133;
        padding-bottom: 70px;
        text-align: center;
      }

      .article-date {
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .clock-icon-icon {
          margin-right: 15px;
          height: 20px;
        }
        .date {
          font-size: 25px;
          font-weight: 700;
        }
      }

      .article-content {
        // padding-left: 300px;
        // padding-right: 300px;
        padding-bottom: 170px;

        .article-content-paragraph {
          text-align: justify;
          margin-bottom: 45px;
          font-size: 20px;
          line-height: 1.75;
        }

        .article-content-image {
          margin-bottom: 75px;

          img {
            width: 100%;
          }
        }

        .article-content-subtitle {
          font-size: 35px;
          font-weight: 700;
          margin-bottom: 40px;
          line-height: 1.143;
        }

        .article-content-highlighted-text {
          padding-left: 30px;
          font-size: 22px;
          line-height: 1.591;
          margin-bottom: 45px;
        }
      }
    }

    .other-news-container {
      .other-news-title {
        font-size: 75px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 80px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .news-individual-page {
    font-family: "Helvetica Neue";
    .article-container {
      .article-photo {
        margin-bottom: 45px;

        img {
          height: 233px;
          width: 100%;
          object-fit: cover;
        }
      }

      .article-title {
        font-size: 30px;
        color: black;
        font-weight: 700;
        line-height: 35px;
        padding-bottom: 30px;
        text-align: center;
      }

      .article-date {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .clock-icon-icon {
          margin-right: 15px;
          height: 10px;
          width: 10px;
        }
        .date {
          font-size: 15px;
          font-weight: 700;
        }
      }

      .article-content {
        .article-content-paragraph {
          text-align: justify;
          margin-bottom: 30px;
          color: #808080;
          font-size: 15px;
          line-height: 25px;
        }

        .article-content-image {
          margin-bottom: 40px;

          img {
            width: 100%;
          }
        }

        .article-content-subtitle {
          font-size: 24px;
          color: black;
          font-weight: 700;
          margin-bottom: 25px;
          line-height: 27px;
        }

        .article-content-highlighted-text {
          border-left: solid 1px #fbeb24;
          padding-left: 20px;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 30px;
        }
      }
    }

    .other-news-container {
      .other-news-title {
        font-size: 75px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 80px;
      }
    }

    .article-content-container {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 75px;
    }
  }
}
