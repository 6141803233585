@media only screen and (min-width: 1024px) {
  .sponsors-container-3 {
    font-family: "Montserrat Bold";
    margin-bottom: 155px;
    .sponsors-section-title {
      font-size: 75px;
      text-align: center;
      margin-bottom: 90px;
    }

    .sponsors-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // filter: grayscale(100%);

      .sponsor-item-box {
        height: 70px;
        width: calc((100vw - 200px) / 7);
        position: relative;
        margin-bottom: 25px;
        border-radius: 20px;
        background-color: white;
        cursor: pointer;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 7vw;
          max-height: 70px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sponsors-container-3 {
    font-family: "Montserrat Bold";
    margin-bottom: 75px;
    .sponsors-section-title {
      font-size: 45px;
      text-align: center;
      margin-bottom: 35px;
    }

    .sponsors-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // filter: grayscale(100%);

      .sponsor-item-box {
        height: 85px;
        width: 110px;
        position: relative;
        margin-bottom: 20px;
        border-right: solid 1px #f2f2f2;
        background-color: #ffffff;
        border-radius: 20px;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          max-height: 45px;
          max-width: 75%;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .sponsors-list :nth-child(2n) {
    border: none !important;
  }

  .sponsors-list :last-child {
    border: none !important;
  }
}

@media only screen and (max-width: 480px) and (min-width: 370px) {
  .sponsors-list :nth-child(3n) {
    border: none !important;
  }

  .sponsors-list :last-child {
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
  }

  .sponsor-item-box :last-child {
    border: none;
  }
}

@media only screen and (max-width: 590px) and (min-width: 480px) {
  .sponsors-list :nth-child(4n) {
    border: none !important;
  }

  .sponsors-list :last-child {
    //border-left: solid 1px #f2f2f2;
    border-right: none !important;
  }
}

@media only screen and (max-width: 649px) and (min-width: 590px) {
  .sponsors-list :nth-child(4n) {
    border: none !important;
  }

  .sponsors-list :last-child {
    //border-left: solid 1px #f2f2f2;
    border-right: none !important;
  }
}

@media only screen and (max-width: 759px) and (min-width: 650px) {
  .sponsors-list :last-child {
    border: none !important;
  }

  .sponsors-list :nth-child(5n) {
    border-right: none !important;
  }
}

@media only screen and (max-width: 869px) and (min-width: 760px) {
  .sponsors-list :last-child {
    border: none !important;
  }

  .sponsors-list :nth-child(6n) {
    border-right: none !important;
  }
}

@media only screen and (max-width: 979px) and (min-width: 870px) {
  .sponsors-list :last-child {
    border: none !important;
  }

  .sponsors-list :nth-child(7n) {
    border-right: none !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 980px) {
  .sponsors-list :last-child {
    border: none !important;
  }

  .sponsors-list :nth-child(8n) {
    border-right: none !important;
  }
}
