@media only screen and (min-width: 720px) {
  .footer-3 {
    font-family: "Helvetica Neue";
    .phone {
      font-family: "Montserrat BlackItalic";
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding-right: 50px;
      font-size: 40px;
      .footer-details-icon {
        height: auto;
        width: 55px;
        padding-right: 20px;
      }
    }
    .address-row {
      font-family: "Montserrat BlackItalic";
      height: 85px;
      display: flex;
      font-size: 16px;
      justify-content: center;
      align-items: center;

      .footer-details-icon {
        height: 20px;
        padding-right: 20px;
        width: auto;
      }

      .address {
        display: flex;
        align-items: center;
        padding-right: 50px;
      }

      .mail {
        display: flex;
        align-items: center;
      }
    }

    .menu-row {
      padding-bottom: 45px;
      padding-top: 70px;

      .menu-options {
        font-family: "Montserrat Bold";
        display: flex;
        justify-content: center;
        .menu-option {
          align-self: center;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 16px;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            height: 10px;
            padding-left: 20px;
          }
        }
      }

      .team-badge {
        text-align: center;

        margin-bottom: 20px;

        img {
          height: 400px;
        }
      }

      .team-name {
        font-family: "Montserrat Bold";
        font-size: 65px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 60px;
        letter-spacing: 15px;
      }
    }
    .copyright-row {
      font-family: "Montserrat Bold";
      padding-top: 20px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      align-items: center;

      .sp-gol {
        width: 45px;
      }
    }
  }

  .extra-footer-3 {
    height: 50px;
    font-family: "Montserrat Bold";
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 720px) {
  .footer-3 {
    font-family: "Montserrat Bold";
    .phone {
      font-style: italic;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 15px;
      img {
        height: 15px;
        width: 15px;
        margin-bottom: 10px;
      }
    }
    .address-row {
      font-style: italic;
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 12px;
      align-items: center;

      .footer-details-icon {
        height: 15px;
        width: 15px;
        margin-bottom: 10px;
      }

      .address {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
        img {
          height: 15px;
          width: 15px;
          margin-bottom: 10px;
        }
      }

      .mail {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 15px;
          width: 15px;
          margin-bottom: 10px;
        }
      }
    }

    .menu-row {
      padding-bottom: 40px;
      padding-top: 45px;

      .menu-options {
        font-family: "Montserrat Bold";
        display: flex;
        justify-content: center;
        flex-direction: column;
        .menu-option {
          align-self: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          img {
            height: 10px;
            padding-left: 10px;
          }
        }
      }

      .team-badge {
        text-align: center;
        margin-bottom: 20px;

        img {
          height: 75px;
        }
      }

      .team-name {
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
      }
    }
    .copyright-row {
      font-family: "Montserrat Bold";
      height: 60px;
      display: flex;
      justify-content: center;
      font-size: 10px;
      align-items: center;

      .sp-gol {
        width: 20px;
      }
    }
  }

  .extra-footer-3 {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat Bold";
    font-size: 12px;
  }
}
