body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url(./fonts/HelveticaNeue/HelveticaNeueLTProHvCn.woff) format("woff");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url(./fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat BlackItalic";
  src: url(./fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat ExtraBold";
  src: url(./fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

body {
  top: 0px !important;
}

.skiptranslate {
  display: none;
}

.skiptranslate.goog-te-gadget {
  display: none;
}

.lang-select {
  position: absolute;
  right: 0%;
  font-size: 15px;
  /* color: #003576; */
  font-weight: 700;
  font-family: "Helvetica Neue";
  border: none;
  top: 15px;
  margin-right: 50px;
  z-index: 9999;
}

.lang-select:focus-visible {
  outline: none;
}

.lang-flag {
  position: absolute;
  right: 0;
  margin-right: 95px;
  top: 18px;
  height: 15px;
}

@media only screen and (max-width: 1576px) {
  .lang-select {
    top: 20px;
  }
  .page-lang {
    height: 40px !important;
  }
  .lang-flag {
    top: 23px;
  }
}

@media only screen and (max-width: 720px) {
  .lang-select {
    margin-right: 20px;
  }
  .lang-flag {
    margin-right: 65px;
  }
}
