@media only screen and (min-width: 1024px) {
  .team-page-3 {
    font-family: "Montserrat Bold";
    .team-page-title {
      font-size: 100px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 100px;
    }

    .team-page-subtitle {
      font-size: 75px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 70px;
    }

    .team-page-menu-desktop {
      height: 90px;
      display: flex;
      justify-content: center;
      margin-bottom: 75px;

      .active-menu {
        background-color: black;
        color: #fbeb24;
      }
      .team-page-option-icon {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
    }

    .players-list {
      margin-bottom: 150px;
      .players-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .player-card-3 {
    width: 325px;
    height: 550px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    margin-left: 12.5px;
    margin-right: 12.5px;
    margin-bottom: 70px;
    position: relative;

    .player-image {
      margin-bottom: 40px;
      position: relative;
      height: 345px;

      .player-profile-image {
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
      }

      .position-label {
        height: 45px;
        background-color: white;
        color: black;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        .position-label-img {
          margin-right: 10px;
          height: 12px;
          width: 12px;
        }
      }
    }

    .player-details {
      display: flex;
      margin-bottom: 25px;
      font-size: 35px;
      line-height: 38px;

      .player-name {
        width: 235px;
        height: 110px;
        padding-right: 20px;
      }
      .player-number {
        color: #fbeb24;
        width: 90px;
        text-align: center;
      }
    }

    .player-age-dob {
      padding-bottom: 45px;
      color: #999999;
      padding-left: 90px;
    }
  }

  .staff-card-3 {
    width: 325px;
    height: 525px;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    margin-left: 12.5px;
    margin-right: 12.5px;
    margin-bottom: 125px;
    position: relative;
    font-family: "Montserrat Bold";

    .staff-image {
      height: 400px;
      position: relative;
      border-radius: 30px 30px 0 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px 30px 0 0;
      }
    }
    .staff-label {
      height: 45px;
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;
    }
    .staff-name {
      margin-top: 10px;
      text-align: center;
      font-size: 20px;
      line-height: 38px;
    }
  }

  .other-groups-carousel-container-not-enough-el-3 {
    margin-bottom: 115px;
    display: flex;
    justify-content: space-around;
  }

  .other-groups-carousel-container-3 {
    position: relative;
    margin-bottom: 115px;

    .other-groups-carousel-buttons {
      display: flex;
      justify-content: space-between;
      bottom: 50%;
      position: absolute;
      left: -50px;
      right: -50px;

      .prev-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .prev-button-img {
          height: 15px;
        }
      }

      .next-button-desktop-teams {
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .next-button-img {
          height: 15px;
        }
      }
    }
    .other-groups-carousel-buttons-mobile {
      display: none;
    }
  }

  .see-more-button-staff-3,
  .see-more-button-player-3 {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -13px;

    .arrow-button-3 {
      font-family: "Montserrat SemiBold";
      padding: 5px 45px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .team-page-3 {
    font-family: "Montserrat Bold";
    .team-page-title {
      font-size: 45px;
      line-height: 50px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 40px;
    }

    .team-page-subtitle {
      font-size: 35px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 25px;
    }

    .team-page-menu-desktop {
      display: none;
    }

    .players-list {
      margin-bottom: 75px;
      .players-section {
        display: grid;
        justify-content: center;
        grid-gap: 50px;
        grid-template-columns: repeat(auto-fill, 190px);
      }
    }
  }

  .player-card-3 {
    width: 190px;
    height: 300px;
    box-shadow: 0px 25px 50px 0px rgb(0 0 0 / 5%);
    background-color: white;
    margin-bottom: 35px;
    position: relative;

    .player-image {
      margin-bottom: 20px;
      position: relative;
      height: 195px;
      .player-profile-image {
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .position-label {
        height: 27px;
        font-size: 10px;
        background-color: white;
        color: black;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        img {
          margin-right: 10px;
          height: 10px;
          width: 10px;
        }
      }
    }

    .player-details {
      display: flex;
      margin-bottom: 10px;
      font-size: 17px;
      line-height: 18.18px;

      .player-name {
        width: 114px;
        height: 70px;
        padding-right: 10px;
      }
      .player-number {
        color: #fbeb24;
        width: 41px;
        text-align: center;
      }
    }

    .player-age-dob {
      font-size: 10px;
      line-height: 12px;

      color: #999999;
      padding-left: 41px;
    }
  }

  .staff-card-3 {
    font-family: "Montserrat Bold";
    width: 190px;
    height: 300px;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    margin-bottom: 75px;
    position: relative;

    .staff-image {
      margin-bottom: 10px;
      position: relative;
      height: 195px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .staff-label {
      height: 38px;
      display: flex;
      font-size: 12px;
      line-height: 11px;
      justify-content: center;
    }
    .staff-name {
      text-align: center;
      font-size: 16px;
      line-height: 27px;
      height: 50px;
    }
  }
  .other-groups-carousel-container-3 {
    position: relative;
    margin-bottom: 30px;

    .other-groups-carousel-buttons {
      display: none;
    }
    .other-groups-carousel-buttons-mobile {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      .prev-button-desktop-teams {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .prev-button-img {
          height: 10px;
        }
      }

      .next-button-desktop-teams {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        .next-button-img {
          height: 10px;
        }
      }
    }
  }

  .see-more-button-staff-3,
  .see-more-button-player-3 {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 7px;

    .arrow-button-3 {
      font-family: "Montserrat SemiBold";
      padding: 4px 20px;
      font-size: 14px;
    }
  }
}
