@media only screen and (min-width: 1200px) {
  .donations-page {
    margin-top: 150px;
  }
  .donation-container {
    font-family: "Helvetica Neue";
    // padding-left: 250px;
    // padding-right: 250px;
    padding-left: 10%;
    padding-right: 10%;

    .donation-title {
      font-size: 100px;
      // line-height: 100px;
      color: black;
      // font-weight: 700;
      margin-bottom: 75px;
      text-align: center;
    }
  }

  .photo-desc {
    margin-bottom: 0px;
    padding-left: 6%;
    padding-right: 50px;
  }

  .donation-section {
    display: flex;
    padding-bottom: 150px;
    .contact-form-container {
      width: 50%;
      margin-right: 40px;
    }
  }

  .about-photo {
    margin-left: 40px;
    margin-bottom: 0px;

    img {
      max-height: 575px;
      width: 100%;
      object-fit: cover;
    }
  }

  .input-select {
    // padding-bottom: 50px;
  }

  .select-donations {
    // padding-top: 25px;
  }

  .drop-select {
    padding-top: 10px;
  }

  .subtitle-font {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .send-button {
    // width: 780px;
    height: 80px;
    border-radius: 40px;
    padding-top: 40px;
  }

  .amount-button {
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .donation-photo {
    margin-bottom: 20px;
    // width: calc(100% - 40px);
    width: 100%;
  }

  .donation-subtitle {
    font-size: 41px;
    // color: black;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: auto;
    line-height: 40px;
  }

  .donation-paragraph {
    margin-bottom: 45px;
    margin-left: auto;
    font-size: 20px;
    text-align: justify;
  }

  .donation-text-container {
    padding-top: 15px;
    padding-left: 6%;
    padding-right: 50px;
  }

  .button-row {
    justify-content: space-between;
  }

  .description-container {
    width: 50%;
  }
}

.form-row {
  padding-left: 5px;
}

.input-amount {
  padding-left: 5px;
}

.recurring-row {
  padding-left: 5px;
}

.padding-top {
}

.select-amount {
  padding-top: 55px;
}

.checkbox {
  margin-bottom: 25px;
  padding-left: 5px;
}

.group {
  // margin-bottom: 10px;
}

.error-row {
  text-align: center;
  font-size: 16px;
  padding-top: 25px;
}

.custom-button {
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .donation-container {
    font-family: "Helvetica Neue";
    // padding-left: 250px;
    // padding-right: 250px;
    // padding-left: 10%;
    // padding-right: 10%;

    .donation-title {
      font-size: 45px;
      // line-height: 100px;
      color: black;
      // font-weight: 700;
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .section-title {
    margin: 0 33px;
  }

  .form-row {
    display: flex;
    flex-direction: column;
    padding: 0 35px;
  }

  .amount-button {
    margin: 0 33px;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .custom-button {
    font-size: 18px;
  }

  // .button-row {
  //   margin-top: 20px;
  //   margin-bottom: 30px;
  //   margin-left: 33px;
  //   margin-right: 33px;
  //   // font-size: 28px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 20px;
  // }

  .donation-section {
    text-align: left;
    margin-bottom: 45px;
    display: inline-block;
  }

  .subtitle-font {
    font-size: 24px;
  }

  .send-button {
    width: 100%;
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .photo-desc {
    position: static;
  }

  .donation-photo {
    margin-bottom: 65px;
    margin-top: 76px;
    width: 100%;

    img {
      width: inherit;
    }
  }

  .donation-text-container {
    .donation-subtitle {
      font-size: 24px;
      margin-bottom: 30px;
      line-height: 40px;
    }

    .donation-paragraph {
      margin-bottom: 45px;
      font-size: 18px;
      text-align: justify;
    }
  }

  .input-select {
    // padding-top: 25px;
    // padding-left: 27px;
  }

  .select-donations {
    margin: 0 33px;
    margin-top: 10px;
  }

  .select-amount {
    padding-top: 25px;
  }

  .checkbox {
    margin-bottom: 25px;
    padding-left: 30px;
  }

  .drop-select {
    padding-top: 10px;
    padding-left: 30px;
  }
}
