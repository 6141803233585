$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: $main-color;
}

.input-select {
  &:focus {
    outline: none;
    //  border-bottom: 1px solid #fbeb24;
  }

  &:focus ~ .child-input-label {
    @include shrinkLabel();
  }
}

.align-corner-left .form-input {
  margin: 0;
  width: 70px;
}

.form-row.top-margin {
  margin-top: 25px;
}

.top-margin > div > div > label {
  margin: 0;

  span:first-of-type {
    padding-left: 0;
  }

  span:last-of-type {
    margin-left: 10px;
    font-family: "Helvetica Neue" !important;
  }
}

.align-corner-left {
  display: flex;
  align-items: flex-end;
  align-content: center;
  justify-content: flex-start;

  label,
  input {
    height: 38px;
  }

  label {
    margin-bottom: 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
}

.MuiCheckbox-root svg {
  color: black;
}

.form-row:nth-last-of-type(2) .group {
  margin-bottom: 0;
}

// @media only screen and (min-width: 1200px) {
//   .personal-data-form-container,
//   .setup-form-container {
//     font-family: "Helvetica Neue";
//     padding-left: 95px;
//     padding-right: 95px;

//     .personal-data-form-content {
//       // display: flex;
//       //margin-bottom: 55px;
//       .form-row {
//         // display: flex;
//         justify-content: space-between;
//         // padding-right: 100px;
//       }

//       .button-row {
//         //   padding-right: 100px;
//         //   display: flex;
//         justify-content: flex-end;
//       }
//     }

//     .personal-data-form-title,
//     .setup-form-container-title {
//       font-size: 35px;
//       padding-bottom: 50px;
//     }
//   }

//   .send-icon {
//     width: 15px;
//     height: 15px;
//   }

//   .rigth {
//     margin-left: 20px;
//   }

//   .left {
//     margin-right: 20px;
//   }

//   .select {
//     width: 45%;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .personal-data-form-container,
//   .setup-form-container {
//     font-family: "Helvetica Neue";

//     .personal-data-form-content {
//       margin-bottom: 55px;
//       .form-row {
//         //   display: flex;
//         flex-direction: column;
//         padding-left: 35px;
//         padding-right: 35px;
//       }

//       .button-row {
//         text-align: -webkit-center;
//       }
//     }

//     .personal-data-form-title,
//     .setup-form-container-title {
//       font-size: 24px;
//       text-align: center;
//       padding-bottom: 30px;
//     }
//   }

//   .send-icon {
//     width: 10px;
//     height: 10px;
//   }
//   .rigth {
//     margin-left: 10px;
//   }

//   .left {
//     margin-right: 10px;
//   }
// }

// // .child-input-label {
// //   @include shrinkLabel();
// // }

// .child-input-label {
//   display: inline-block;
//   margin-bottom: 0.5rem;
// }

// @media only screen and (min-width: 1200px) {
//   .child-input-label {
//     color: $sub-color;
//     font-size: 16px;
//     font-weight: normal;
//     // position: absolute;
//     pointer-events: none;
//     left: 5px;
//     top: 10px;
//     transition: 300ms ease all;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .child-input-label {
//     color: $main-color;
//     font-size: 12px;
//     font-weight: normal;
//     // position: absolute;
//     pointer-events: none;
//     left: 5px;
//     top: 10px;
//     transition: 300ms ease all;
//   }
// }

@media only screen and (min-width: 1200px) {
  .personal-data-form-container,
  .setup-form-container {
    font-family: "Helvetica Neue";
    padding-left: 95px;
    padding-right: 95px;

    .personal-data-form-content {
      // display: flex;
      // margin-bottom: 55px;

      .form-row {
        // display: flex;
        justify-content: space-between;
        // padding-right: 100px;
      }
      .custom-form-row {
        margin-bottom: 25px;
      }
      .form-row.align-right {
        justify-content: flex-start;
      }
      .button-row {
        // padding-right: 100px;
        // display: flex;
        justify-content: flex-end;
      }
      .no-margin-bottom {
        margin-bottom: 0;
      }

      .quarter-width {
        width: 22.5%;
      }

      .ninety-width {
        width: 90% !important;
      }
    }
    .personal-data-form-title,
    .setup-form-container-title {
      font-size: 35px;
      padding-bottom: 50px;
    }
  }

  .send-icon {
    width: 15px;
    height: 15px;
  }

  .rigth {
    margin-left: 20px;
  }

  .left {
    margin-right: 20px;
  }
  .select {
    width: 45%;
  }
}

@media only screen and (max-width: 1200px) {
  .personal-data-form-container,
  .setup-form-container {
    font-family: "Helvetica Neue";

    .personal-data-form-content {
      margin-bottom: 55px;

      .form-row {
        // display: flex;
        flex-direction: column;
        padding-left: 35px;
        padding-right: 35px;
      }
      .button-row {
        text-align: -webkit-center;
      }

      .align-corner-left {
        margin-top: 10px;
        .child-input-label {
          padding-top: 5px;
        }
      }
      .top-margin {
        margin-top: 10px;
      }
    }
    .personal-data-form-title,
    .setup-form-container-title {
      font-size: 24px;
      text-align: center;
      padding-bottom: 30px;
    }
  }
  .send-icon {
    width: 10px;
    height: 10px;
  }
  .rigth {
    margin-left: 10px;
  }

  .left {
    margin-right: 10px;
  }
}

// .child-input-label {
//   @include shrinkLabel();
// }

.child-input-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 1200px) {
  .child-input-label {
    color: $sub-color;
    font-size: 16px;
    font-weight: normal;
    // position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    // top: 10px;
    transition: 300ms ease all;
  }
}

@media only screen and (max-width: 1200px) {
  .child-input-label {
    color: $main-color;
    font-size: 12px;
    font-weight: normal;
    // position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }
}

@media only screen and (max-width: 768px) {
  form .custom-button {
    width: 100%;
    height: 60px;
    font-size: 14px;
  }

  form .button-row {
    padding: 0 35px;
  }
}

.commision-message {
  font-size: 12px;
  font-family: "Helvetica";
  margin-top: 10px;
}
