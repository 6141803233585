// @media only screen and (min-width: 1340px) {

.player-photo-gallery-container-mobile,
.player-video-gallery-container-mobile {
  display: none;
}

.field-name {
  color: grey;
  font-size: 16px;
  font-weight: normal;
}
.player-page-container {
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat Bold";

  .player-image-album {
    margin-right: 100px;

    .player-image {
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
      width: 450px;
      // margin: 20px;

      img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        margin: 30px 0;
      }
    }
    .player-photo-gallery-container,
    .player-video-gallery-container {
      .photo-gallery-header,
      .video-gallery-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .photo-gallery-title,
        .video-gallery-title {
          color: white;
          font-size: 25px;
          line-height: 40px;
          // color: #000000;
          // font-family: "Helvetica Neue LT Pro";
        }

        .photo-gallery-buttons-nav,
        .video-gallery-buttons-nav {
          .prev-button-img,
          .next-button-img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
          .next-button-img {
            margin-left: 20px;
          }
        }
      }
      .player-photo-gallery {
        width: 535px;
        // height: 255px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin: 30px 0;
        gap: 10px;

        img {
          width: 120px;
          // height: 100px;
          object-fit: cover;
          // margin: 20px;
        }
        margin: 30px 0;
      }

      .player-video-gallery {
        width: 535px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin: 30px 0;
        gap: 25px;

        .video-preview-container {
          width: 240px;
          .video-preview {
            height: auto;
            width: 240px;
            filter: brightness(60%);
            cursor: pointer;
          }

          .video-name {
            font-size: 20px;
            line-height: 22px;
            color: #000000;
            font-family: "Montserrat Bold";
            margin-top: 10px;
          }
          .play-icon-container {
            height: 0px;
            position: relative;
            top: -50%;
            .play-icon {
              position: relative;
              left: 120px;
              width: 20px;
              height: 20px;
            }
          }
        }

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }

        iframe {
          margin: auto;
          // width: 80%;
        }
      }
    }
  }

  .player-details {
    width: 550px;

    .field-value {
      color: white;
      margin-top: 5px;
      font-size: 18px;
    }
  }

  .player-name-number {
    display: flex;
    margin: 40px 0;

    .vertical-line {
      border-left: 1px solid rgb(0 0 0 / 10%);
      height: auto;
      margin: 10px 10px 8px 10px;
    }

    .player-number {
      font-size: 70px;
      line-height: 1;
    }

    .player-name {
      // width: 235px;
      font-size: 70px;
      line-height: 1;
    }
  }

  .player-info-role-leg {
    display: flex;
  }

  .field-name {
    color: grey;
    font-size: 16px;
    font-weight: normal;
  }

  .vertical-line {
    border-left: 1px solid rgb(0 0 0 / 10%);
    height: auto;
    margin: 0 20px;
  }

  .title-field {
    font-size: 25px;
    padding: 22px 0;
    color: black;
  }

  .title-field-performance {
    font-size: 19px;
  }

  .value-field-performance {
    font-size: 25px;
  }

  .performance {
    // padding: 5px 0;
    margin-bottom: 25px;
  }

  .segment {
    border-top: 5px solid;
    width: 90px;
  }

  .segments {
    display: flex;
    gap: 10px;
  }

  .value-field-performance {
    padding: 6px 0;
  }

  .icon-position {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }

  .player-info-role-leg {
    .field-value {
      display: flex;
    }
  }

  .player-bibliography {
    margin-top: 35px;

    .text-bilbiography {
      line-height: 1.7;
      font-family: arial narrow;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.buttons-previous-next {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 170px;
}

// }

@media only screen and (max-width: 1340px) {
  .player-page-container .player-image-album {
    margin-right: 75px;
  }

  .player-page-container .player-image-album .player-image {
    width: 350px;
    margin: 10px;
  }

  .player-page-container .player-image-album .player-photo-gallery {
    width: 450px;
    display: flow-root;
    margin: 20px 0;
  }

  .player-page-container .player-image-album .player-video-gallery {
    width: 450px;
    margin: 20px 0;
  }

  .player-page-container .player-details {
    width: 400px;
  }
}

@media only screen and (max-width: 1024px) {
  .player-photo-gallery-container,
  .player-video-gallery-container {
    display: none;
  }
  .player-photo-gallery-container-mobile,
  .player-video-gallery-container-mobile {
    display: block;
  }

  .player-photo-gallery-container-mobile,
  .player-video-gallery-container-mobile {
    width: 85%;
    margin-left: auto;
    margin-right: auto;

    .photo-gallery-header,
    .video-gallery-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .photo-gallery-title,
      .video-gallery-title {
        color: white;
        font-size: 20px;
        line-height: 40px;
        font-weight: 700;
        // color: #000000;
        font-family: "Montserrat Bold";
      }

      .photo-gallery-buttons-nav,
      .video-gallery-buttons-nav {
        .prev-button-img,
        .next-button-img {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
        .next-button-img {
          margin-left: 20px;
        }
      }
    }
    .player-photo-gallery {
      // width: 535px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      margin: 30px 0;
      gap: 15px;

      img {
        width: 90px;
        height: auto;
        // object-fit: cover;
        object-fit: contain;
      }
      margin: 30px 0;
    }

    .player-video-gallery {
      // width: 535px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      margin: 30px 0;
      gap: 10px;

      .video-preview-container {
        width: 150px;
        .video-preview {
          height: auto;
          width: 150px;
          filter: brightness(60%);
          cursor: pointer;
        }

        .video-name {
          font-size: 15px;
          line-height: 16px;
          color: #000000;
          font-family: "Montserrat Bold";
          margin-bottom: 10px;
        }
        .play-icon-container {
          height: 0px;
          position: relative;
          top: -50%;
          .play-icon {
            position: relative;
            left: 75px;
            width: 20px;
            height: 20px;
          }
        }
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }

      iframe {
        margin: auto;
        // width: 80%;
      }
    }
  }



  .player-page-container {
    .player-image-album {
      .player-photo-gallery,
      .player-video-gallery {
        display: none !important;
      }
    }
  }
  .field-name {
    color: grey;
    font-size: 16px;
    font-weight: normal;
  }
  .player-page-container {
    margin-top: 80px;
    margin-bottom: 50px;
    font-family: "Montserrat Bold";
    display: block;

    .player-image-album {
      width: 85%;
      margin: auto;

      .player-image {
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
          margin: 30px 0;
        }
      }

      .player-photo-gallery {
        display: flow-root;
        margin-left: auto;
        margin-right: auto;
        width: 65%;

        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          margin: 20px;
        }
      }

      .player-video-gallery {
        margin-left: auto;
        margin-right: auto;

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }
      }
    }

    .player-details {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }

    .player-name-number {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;

      .vertical-line {
        border-left: 1px solid rgb(0 0 0 / 10%);
        height: auto;
        margin: 0 10px;
      }

      .player-number {
        font-size: 50px;
        line-height: 1.25;
      }

      .player-name {
        width: auto;
        font-size: 50px;
        line-height: initial;
      }
    }

    .player-info-role-leg {
      display: flex;
    }

    .field-name {
      color: grey;
      font-size: 16px;
      font-weight: normal;
    }

    .vertical-line {
      border-left: 1px solid rgb(0 0 0 / 10%);
      height: auto;
      margin: 0 20px;
    }

    .title-field {
      font-size: 25px;
      padding: 22px 0;
      color: black;
    }

    .value-field-performance {
      font-size: 25px;
    }

    .performance {
      // padding: 5px 0;
      margin-bottom: 25px;
    }

    .segment {
      border-top: 3px solid black;
      width: 90px;
    }

    .segments {
      display: flex;
      gap: 10px;
    }

    .value-field-performance {
      padding: 6px 0;
    }

    .icon-position {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }

    .player-info-role-leg {
      .field-value {
        display: flex;
      }
    }
  }

  .title-field {
    font-size: 25px;
    padding: 22px 0;
    color: black;
  }

  // .player-video-gallery-container-mobile {
  //   width: 80%;
  //   margin-left: auto;
  //   margin-right: auto;
  //   margin-bottom: 100px;
  //   font-family: "Helvetica Neue";
  //   font-weight: 400;
  //   line-height: 1.5;

  //   .player-photo-gallery {
  //     justify-content: normal;
  //     margin-top: 10px;
  //     margin-bottom: 40px;
  //     img {
  //       // margin: 10px;
  //     }
  //   }

  //   .player-video-gallery {
  //     width: 100%;
  //   }
  // }

  .buttons-previous-next {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 170px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .gallery-content {
    .player-photo-gallery {
      display: flex !important;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
    }
  }
  .player-page-container {
    .player-details {
      // width: 550px;

      .field-value {
        color: white;
        margin-top: 0px;
        font-size: 18px;
      }
    }
  }

  .player-photo-gallery {
    img {
      // height: 120px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .player-name-number {
    .player-name {
      font-size: 40px !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  img {
    // height: auto !important;
    // max-width: 100%;
  }

  .player-image {
    width: 100% !important;
  }

  .player-video-gallery {
    width: 100% !important;
  }
  .player-photo-gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    img {
      // margin: 10px !important;
      // width: 70px !important;
    }
  }

  .player-photo-gallery {
    img {
      // height: 100px !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  .player-page-container {
    font-size: 12px;

    .field-name {
      font-size: 12px;
    }

    .value-field-performance {
      font-size: 25px;
    }
  }

  .player-name-number {
    justify-content: normal !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin: 20px 0 !important;
    .player-name {
      font-size: 35px !important;
    }

    .player-number,
    .vertical-line {
      display: none;
    }
  }
  .position.pos-strong-leg {
    margin-bottom: 0px !important;
  }

  .player-info-role-leg {
    .vertical-line {
      margin: 4px 20px 18px 20px;
    }
    //display: block !important;
  }

  .position {
    margin-bottom: 15px !important;
  }

  .player-info-role-leg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .photo-video-gallery {
    .player-photo-gallery {
      margin-top: 0px;
    }
  }

  .photo-video-gallery {
    margin-bottom: 60px;
  }
  .player-page-container {
    .player-bibliography {
      margin-top: 0px;
    }
  }
  .player-page-container {
    .value-field-performance {
      padding: 0;
    }
  }
  .player-photo-gallery {
    img {
      // height: 70px !important;
    }
  }

  .player-page-container {
    .player-image-album {
      .player-image {
        img {
          width: 100%;
          height: 400px;
          object-fit: cover;
          margin: 30px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .player-info-role-leg > div {
    // flex: 1 0 50%;
  }

  .player-info-role-leg > div:last-child {
    flex: 0 1 100%;
  }

  .vertical-line.second {
    display: none;
  }

  .gallery-content {
    .player-photo-gallery {
      display: flex;
      gap: 10px;
      justify-content: flex-start;
    }
  }
}
@media only screen and (max-width: 400px) {
  .player-page-container {
    .player-image-album {
      .player-image {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          margin: 30px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .gallery-content {
    .player-photo-gallery {
      // justify-content: center;
    }
  }
}

@media only screen and (max-width: 320px) {
  .player-video-gallery {
    // width: 100px !important;
  }
}

@media only screen and (max-width: 312px) {
  .player-info-role-leg > div:last-child {
    flex: 0 1 100%;
  }

  .vertical-line {
    display: none;
  }

  .player-info-role-leg {
    display: block !important;
  }
}
