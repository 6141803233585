.app {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 720px) {
  .app {
    padding-left: 20px;
    padding-right: 20px;
  }
}
