$main-color: #fbeb24;

@media only screen and (min-width: 1200px) {
  .dark-background-popup-homepage {
    position: fixed;
    top: 0;
    z-index: 3333;
    width: 100%;
    height: 100%;
    margin-left: -50px;
    background-color: rgba(0, 0, 0, 0.7);

    .expanded-image-buttons {
      display: flex;
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
      .button-expanded-image {
        width: 75px;
        height: 80px;
        background-color: $main-color;
        position: relative;

        img {
          height: 13px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .expanded-image-buttons-mobile {
      display: none;
    }

    .image-container {
      width: calc(100vw - 200px);
      height: 879px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;

      .expanded-image {
        max-height: 100%;
        max-width: calc(100% - 75px);
      }

      .close-button {
        width: 50px;
        height: 50px;
        background-color: $main-color;
        margin-left: 20px;
        cursor: pointer;
        position: relative;

        .close-button-img {
          height: 13px;
          width: 13px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .dark-background-popup-homepage {
    position: fixed;
    top: 0;
    z-index: 3333;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    margin-left: -20px;
    z-index: 999999;

    .expanded-image-buttons {
      display: none;
    }
    .expanded-image-buttons-mobile {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      width: 100%;
      .button-expanded-image {
        width: 50px;
        height: 50px;
        background-color: $main-color;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;

        img {
          height: 10px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .image-container {
      width: calc(100vw - 40px);
      height: auto;
      padding-top: 20px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;

      .expanded-image {
        max-height: 320px;
        object-fit: contain;
        max-width: 100%;
      }

      .close-button {
        width: 32px;
        height: 32px;
        background-color: $main-color;
        cursor: pointer;
        position: relative;
        margin-bottom: 100px;
        align-self: flex-end;

        .close-button-img {
          height: 8px;
          width: 8px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}
