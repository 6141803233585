@media only screen and (min-width: 1200px) {
  .social-section {
    font-family: "Helvetica Neue";
    margin-bottom: 75px;
    padding-left: 50px;
    padding-right: 50px;

    .social-section-title {
      font-size: 75px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 75px;
    }

    .social-media-menu {
      display: flex;
      place-content: center;
      height: 90px;
      margin-bottom: 70px;

      .social-media-menu-icon {
        height: 20px;
        margin-right: 20px;
        width: 20px;
      }

      .selected {
        background-color: black;
        color: #fbeb24;
        fill: #fbeb24;
      }
    }

    .social-media-post-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .social-element-container {
    height: 475px;
    width: 410px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 25px;
    margin-bottom: 75px;
    background-color: white;

    .image-container {
      height: 300px;
      position: relative;

      &:hover img {
        filter: brightness(60%);
        cursor: pointer;
      }

      &:hover .play-icon {
        display: block;
      }

      .element-tag {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 45px;
        background-color: white;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 20px;
        display: flex;
        align-items: center;

        .image-container {
          border-right: solid 1px #e6e6e6;
          height: 100%;
          padding-left: 20px;
          padding-right: 20px;
          margin-right: 20px;
          display: flex;
          align-items: center;

          .tag-image {
            height: 15px;
            width: 15px;
          }
        }

        .tag-name {
          font-size: 14px;
        }
      }

      .play-icon {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 25px;
        height: 25px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-container {
      padding-left: 50px;
      padding-right: 50px;
      margin-top: 50px;
      font-size: 16px;
      line-height: 22px;
      height: 66px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .link-icon {
        width: 20px;
        height: 20px;
        margin-left: 50px;
        cursor: pointer;
      }
    }
  }

  .social-carousel-buttons-mobile {
    display: none;
  }

  .social-media-mobile-menu {
    display: none;
  }

  .social-carousel-buttons-desktop {
    .prev-button-desktop {
      display: flex !important;
      background: black;
      height: 75px;
      width: 75px;
      top: 0;
      position: absolute;
      left: -100px;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;

      .prev-button-img {
        margin: auto;
        display: block;
        width: 20px;
        height: 20px;
      }
    }
    .next-button-desktop {
      display: flex !important;
      background: black;
      height: 75px;
      width: 75px;
      top: 0;
      position: absolute;
      right: -100px;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;

      .next-button-img {
        margin: auto;
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  .social-media-see-all-desktop {
    text-align: -webkit-center;
  }

  .social-carousel-container {
    position: relative;
  }
}

@media only screen and (max-width: 1200px) {
  .social-section {
    font-family: "Helvetica Neue";
    margin-bottom: 75px;

    .social-section-title {
      font-size: 45px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    .social-media-menu {
      display: none;
    }

    .social-media-post-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .social-media-see-all-desktop {
    display: none;
  }

  .social-media-mobile-menu {
    text-align: center;
    margin-bottom: 35px;
    .menu-mobile-dropdown {
      height: 50px;
      width: 100%;
      padding: 15px;
      font-size: 10px;
    }
  }

  .social-element-container {
    height: 300px;
    // width: 70vw;
    width: 320px;
    box-shadow: 0px 1px 5px 0.5px #ccc;
    background-color: white;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    .image-container {
      height: 180px;
      position: relative;

      &:hover {
        filter: brightness(60%);
        cursor: pointer;
      }

      &:hover .play-icon {
        display: block;
      }

      .element-tag {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 25px;
        background-color: white;
        height: 25px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        font-size: 10px;

        .image-container {
          border-right: solid 1px #e6e6e6;
          height: 100%;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 15px;
          display: flex;
          align-items: center;

          .tag-image {
            height: 10px;
            width: 10px;
          }
        }
      }

      .play-icon {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 15px;
        height: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-container {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 30px;
      font-size: 12px;
      line-height: 22px;
      height: 66px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .link-icon {
        width: 15px;
        height: 15px;
        margin-left: 50px;
        cursor: pointer;
      }
    }
  }

  .social-carousel-buttons-mobile {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .prev-button-mobile-news {
      height: 50px;
      width: 50px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;

      .prev-button-img {
        height: 10px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }

    .next-button-mobile-news {
      height: 50px;
      width: 50px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;

      .next-button-img {
        height: 10px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }
  }

  .social-carousel-buttons-desktop {
    display: none;
  }

  #root
    > div
    > div:nth-child(2)
    > div.social-section
    > div.social-carousel-container
    > div.alice-carousel
    > div
    > div
    > ul
    > li.alice-carousel__stage-item.__active {
    // width: 80vw !important;
  }
}

#root
  > div
  > div:nth-child(2)
  > div.social-section
  > div.react-multi-carousel-list {
  overflow: visible;
}

.social-media-see-all-icon {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.embed-video-dialog-content {
  padding: 0 !important;
}

.embed-video-container {
  overflow: hidden;
}
