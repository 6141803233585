@media only screen and (min-width: 1024px) {
  .news-page-3 {
    font-family: "Montserrat Bold";
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 170px;
    .news-page-title {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 50px;
      margin-top: 100px;
    }

    .news-container {
      display: grid;
      justify-content: center;
      grid-gap: 25px;
      //grid-template-rows: repeat(2, 1fr);
      // grid-template-columns: repeat(4, 1fr);
      margin-bottom: 125px;
      // grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, 410px);
    }

    .pagination-container {
      display: flex;
      justify-content: center;

      .page-box {
        width: 80px;
        height: 80px;
        margin-left: 20px;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
      }

      .change-page-button {
        .change-page-button-img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          height: 13px;
        }
      }

      .page-number-button {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #fbeb24;
        }

        div {
          font-size: 16px;
        }
      }

      .active-page {
        background-color: #fbeb24;
        width: 200px;
      }
    }
  }

  .news-preview-container-3 {
    height: 603px;
    width: 411px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    position: relative;
    margin-bottom: 35px;
    border-radius: 30px;

    .image-container {
      position: relative;

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 30px 30px 0 0;
      }
    }

    .content-container {
      padding: 15px 15px 0 15px;

      .news-title {
        font-size: 24px;
        line-height: 40px;
        overflow: hidden;
        height: 80px;
        text-align: center;
      }

      .news-description {
        font-family: "Montserrat Medium";
        font-size: 16px;
        line-height: 22px;
        height: 110px;
        overflow: hidden;
        text-align: center;
      }
    }

    .discover-more-button-3 {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: -65px;
    }
  }

  .loader-container-3 {
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .news-page-3 {
    font-family: "Montserrat Bold";
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 75px;
    .news-page-title {
      font-size: 45px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 40px;
    }

    .news-container {
      display: grid;
      justify-content: center;
      grid-gap: 25px;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 40px;
    }

    .pagination-container {
      display: flex;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;

      .page-box {
        width: 50px;
        height: 50px;

        position: relative;
        cursor: pointer;
      }

      .change-page-button {
        background-color: black;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          height: 10px;
          width: 10px;
        }
      }

      .page-number-button {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
          background-color: #fbeb24;
        }

        div {
          font-size: 10px;
        }
      }

      .active-page {
        background-color: #fbeb24;
        width: 120px;
      }

      .second-number {
        display: none !important;
      }

      .dots-mobile {
        width: 8px !important;
      }
    }
  }

  .news-preview-container-3 {
    height: 415px;
    width: 100%;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    position: relative;
    margin-bottom: 35px;
    border-radius: 30px;

    .image-container {
      position: relative;

      img {
        width: 100%;
        height: 205px;
        object-fit: cover;
        border-radius: 30px 30px 0 0;
      }
    }

    .content-container {
      padding: 10px 15px 0 15px;

      .news-title {
        text-align: center;
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 20px;
        height: 54px;
        overflow: hidden;
      }

      .news-description {
        font-size: 12px;
        line-height: 17px;
        height: 85px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }
    }

    .discover-more-button-3 {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: -15px;
    }
  }

  .loader-container-3 {
    text-align: center;
  }
}
