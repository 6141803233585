$main-color: #fbeb24;

@media only screen and (min-width: 1024px) {
    .dark-background-popup-homepage {
        position: fixed;
        top: 0;
        z-index: 33333;
        width: 100%;
        height: 100%;
        margin-left: -50px;
        background-color: rgba(0, 0, 0, 0.7);

        .expanded-image-buttons {
            display: flex;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            width: 100%;
            .button-expanded-image {
                width: 75px;
                height: 80px;
                background-color: $main-color;
                position: relative;

                img {
                    height: 13px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }

        .expanded-image-buttons-mobile {
            display: none;
        }

        .popup-container {
            width: 100%;
            height: 95%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;

            .expanded-image {
                max-height: 100%;
                max-width: calc(100% - 75px);
            }

            .close-button {
                width: 50px;
                height: 50px;
                background-color: $main-color;
                margin-left: 20px;
                cursor: pointer;
                position: relative;

                .close-button-img {
                    height: 13px;
                    width: 13px;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                }
            }

            .data-video-container {
                background: white;
                width: 500px;
                padding: 60px;

                .video-title {
                    font-size: 35px;
                    line-height: 38px;
                    font-family: "Helvetica Neue";
                    margin-bottom: 50px;
                }

                .video-events {
                    .video-event {
                        cursor: pointer;
                        .video-event-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .video-event-play-info {
                                display: flex;
                                .video-event-playing {
                                    width: 10px;
                                    height: auto;
                                    background-color: #fbeb24;
                                    margin-right: 15px;
                                }
                                .video-event-info {
                                    // margin-bottom: 20px;
                                    .video-event-minute {
                                        font-size: 16px;
                                        font-weight: 400;
                                        // line-height: 40px;
                                        color: #bfbfbf;
                                        // font-family: "Helvetica Neue";
                                    }
                                    .video-event-name {
                                        font-size: 25px;
                                        line-height: 25px;
                                        // color: #000000;
                                        font-family: "Helvetica Neue";
                                    }
                                }
                            }
                            .video-event-play {
                                .play-icon {
                                    height: 18px;
                                    width: 18px;
                                }
                            }

                            &:hover {
                                padding-left: 25px;
                                transition-duration: 0.5s;
                            }
                        }

                        .video-event-line {
                            height: 1px;
                            background-color: #f2f2f2;
                            margin: 18px 0;
                        }
                        .video-event-timeline {
                            height: 1px;
                            background-color: black;
                            position: relative;
                            top: -19px;
                        }
                    }
                }
            }

            .video-container {
                background: white;
                width: calc(90% - 500px);
                padding: 60px 60px 60px 0px;
                height: auto!important;
                .video-player {
                    width: 100%;
                    filter: brightness(60%);
                    cursor: pointer;

                    // &:hover {
                    //     filter: brightness(80%);
                    // }
                }
                .play-icon-container {
                    padding: 10px;
                    background-color: black;
                    height: 80px;
                    width: 80px;
                    position: relative;
                    // top: -50%;
                    // left: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .play-icon {
                        position: relative;
                        // left: 120px;
                        width: 20px;
                        height: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                        // filter: brightness(80%);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .dark-background-popup-homepage {
        position: fixed;
        top: 0;
        z-index: 3333;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.75);
        background-color: white;
        margin-left: -50px;
        z-index: 999999;

        .expanded-image-buttons {
            display: none;
        }
        .expanded-image-buttons-mobile {
            margin-top: 100px;
            display: flex;
            justify-content: center;
            width: 100%;
            .button-expanded-image {
                width: 50px;
                height: 50px;
                background-color: $main-color;
                position: relative;
                margin-left: 5px;
                margin-right: 5px;

                img {
                    height: 10px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }

        .popup-container {
            width: calc(100vw - 40px);
            height: auto;
            padding-top: 20px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;

            .expanded-image {
                max-height: 320px;
                object-fit: contain;
                max-width: 100%;
            }

            .close-button {
                width: 32px;
                height: 32px;
                background-color: $main-color;
                cursor: pointer;
                position: relative;
                margin-bottom: 50px;
                align-self: flex-end;

                .close-button-img {
                    height: 8px;
                    width: 8px;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                }
            }

            .data-video-container {
                background: white;
                width: 100%;
                padding: 0 25px 25px 25px;

                .video-title {
                    font-size: 35px;
                    line-height: 38px;
                    font-family: "Helvetica Neue";
                    margin-bottom: 35px;
                    margin-top: 35px;
                }

                .video-events {
                    overflow-y: scroll;
                    padding-right: 15px;
                    .video-event {
                        cursor: pointer;
                        .video-event-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .video-event-play-info {
                                display: flex;
                                .video-event-playing {
                                    width: 10px;
                                    height: auto;
                                    background-color: #fbeb24;
                                    margin-right: 15px;
                                }
                                .video-event-info {
                                    // margin-bottom: 20px;
                                    .video-event-minute {
                                        font-size: 16px;
                                        font-weight: 400;
                                        // line-height: 40px;
                                        color: #bfbfbf;
                                        // font-family: "Helvetica Neue";
                                    }
                                    .video-event-name {
                                        font-size: 25px;
                                        line-height: 25px;
                                        // color: #000000;
                                        font-family: "Helvetica Neue";
                                    }
                                }
                            }
                            .video-event-play {
                                .play-icon {
                                    height: 18px;
                                    width: 18px;
                                }
                            }

                            &:hover {
                                padding-left: 0px;
                                transition-duration: 0.5s;
                            }
                        }

                        .video-event-line {
                            height: 1px;
                            background-color: #f2f2f2;
                            margin: 18px 0;
                        }
                        .video-event-timeline {
                            height: 1px;
                            background-color: black;
                            position: relative;
                            top: -19px;
                        }
                    }
                }
            }

            .video-container {
                // background: white;
                // width: calc(90% - 500px);
                // padding: 60px 60px 60px 0px;
                .video-player {
                    // width: 100%;
                    // filter: brightness(60%);
                    // cursor: pointer;

                    // &:hover {
                    //     filter: brightness(80%);
                    // }
                }
                .play-icon-container {
                    padding: 10px;
                    background-color: black;
                    height: 80px;
                    width: 80px;
                    position: relative;
                    // top: -50%;
                    // left: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .play-icon {
                        position: relative;
                        // left: 120px;
                        width: 20px;
                        height: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                        // filter: brightness(80%);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 601px) {
    .dark-background-popup-homepage {
        margin-left: -20px;
    }
}
