@media only screen and (min-width: 768px) {
  .staff-members-container {
    font-family: "Helvetica Neue";
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 132px;
    .staff-members-title {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 70px;
    }

    .staff-list {
      display: grid;
      justify-content: center;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, 324px);
    }
  }

  .staff-member-card {
    height: 525px;
    width: 325px;
    font-family: "Helvetica Neue";
    position: relative;
    margin-bottom: 125px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;

    .staff-member-image-container {
      height: 325px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .staff-member-name {
      margin-top: 40px;
      padding-left: 40px;
      padding-right: 20px;
      font-size: 35px;
      line-height: 38px;
    }

    .staff-member-label {
      height: 45px;
      background-color: white;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }

    .see-more-button-staff {
      text-align: end;
      position: absolute;
      bottom: -40px;
      right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .staff-members-container {
    font-family: "Helvetica Neue";
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .staff-members-title {
      font-size: 45px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 75px;
    }

    .staff-list {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .staff-member-card {
    height: 390px;
    width: 230px;
    font-family: "Helvetica Neue";
    position: relative;
    margin-bottom: 80px;
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
    background-color: white;

    .staff-member-image-container {
      margin-bottom: 30px;
      position: relative;
      height: 245px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .staff-member-name {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 24px;
      line-height: 27px;
    }

    .staff-member-label {
      height: 38px;
      background-color: white;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
      line-height: 11px;
    }

    .see-more-button-staff {
      text-align: end;
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }
}
