$main-color: #fbeb24;

.lang-select{
  // margin-right: 50px;
}

@media only screen and (min-width: 1576px) {
  .header-container-3 {
    margin-bottom: 50px;
    font-family: "Montserrat Bold";
    padding: 0 20px;
    .menu-header {
      height: 90px;
      display: flex;
      justify-content: space-between;

      .badge-name {
        padding-left: 15px;
        display: flex;
        align-items: center;

        .title-text {
          font-size: 24px;
          text-transform: uppercase;
          padding-left: 20px;
        }
      }

      .menu-options {
        display: flex;
        .menu-option {
          align-self: center;
          padding-left: 40px;
          padding-right: 40px;
          font-size: 16px;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .arrow-down-menu {
            height: 10px;
            width: 10px;
            margin-left: 10px;
          }

          img {
            height: 10px;
            padding-left: 20px;
          }
        }

        .active-menu-option {
          background-color: #000000 !important;
          color: $main-color !important;
        }
      }
    }
  }

  .contact-info-container-3 {
    height: 50px;
    display: flex;
    align-items: center;
    padding-right: 95px;
    justify-content: flex-end;

    .phone {
      display: flex;
      padding-right: 50px;

      .phone-text {
        font-size: 14px;
        font-style: italic;
      }

      img {
        height: 20px;
        padding-right: 10px;
      }
    }

    .email {
      display: flex;
      padding-right: 50px;

      .email-text {
        font-size: 14px;
        font-style: italic;
      }

      img {
        height: 20px;
        padding-right: 10px;
      }
    }

    .social-media {
      img {
        height: 22px;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
       
      }
    }
  }

  .menu-mobile-3 {
    display: none;
  }
  .mobile-logo-3 {
    width: 105px;
  }

  .menu-mobile-content-3 {
    display: none;
  }
}

@media only screen and (max-width: 1576px) {
  .contact-info-container-3 {
    display: none;
  }

  .header-container-3 {
    margin-bottom: 45px;
    font-family: "Montserrat BlackItalic";
    padding-top: 20px;
    position: relative;
    .menu-header {
      height: 55px;
      background-color: $main-color;
      display: flex;
      justify-content: space-between;

      .badge-name {
        padding-left: 15px;
        display: flex;
        align-items: center;

        .title-text {
          font-size: 15px;
          text-transform: uppercase;
          padding-left: 10px;
        }
      }

      .menu-options {
        display: none;
      }

      .menu-mobile-3 {
        align-self: center;

        .menu-mobile-img {
          width: 18px;
          height: 18px;
          margin-right: 20px;
          // background-color: #ffffff;
        }
      }
    }

    .mobile-logo-3 {
      width: 60px;
      z-index: 33333;
    }

    .lang-select{
      margin-right: 0px;
    }
  }

  .menu-mobile-content-3 {
    height: 610px;
    width: 100%;
    font-size: 12px;
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 45px;
    position: absolute;
    z-index: 3333;

    .mobile-menu-option {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px #fada23;
    }
  }
}
