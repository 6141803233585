@media only screen and (min-width: 1024px) {
  .gallery-section-container {
    font-family: "Helvetica Neue";
    padding-left: 50px;
    padding-right: 50px;
    .gallery-title {
      font-size: 100px;
      text-transform: uppercase;
      margin-bottom: 125px;
      text-align: center;
    }

    .photos-grid {
      display: grid;
      justify-content: center;
      grid-gap: 25px;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 75px;

      .element-photo-grid {
        position: relative;
        img {
          width: calc((100vw - 275px) / 4);
          height: calc((100vw - 275px) / 4);
          object-fit: cover;
        }
        &:hover .expand-button {
          display: block;
        }

        &:hover img {
          filter: brightness(60%);
          cursor: pointer;
        }

        .expand-button {
          width: 80px;
          height: 80px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: none;
          cursor: pointer;

          .expand-photo-button-img {
            height: 25px;
            width: 25px;
            right: 0;
            top: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
    }

    .gallery-load-more-button-container {
      text-align: -webkit-center;
      margin-bottom: 150px;
    }
  }

  .dark-background-popup {
    position: fixed;
    top: 0;
    z-index: 3333;
    width: 100%;
    height: 100%;
    margin-left: -100px;
    background-color: rgba(0, 0, 0, 0.7);

    .expanded-image-buttons {
      display: flex;
      justify-content: space-between;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
      .button-expanded-image {
        width: 75px;
        height: 80px;
        background-color: #fbeb24;
        position: relative;

        img {
          height: 13px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .expanded-image-buttons-mobile {
      display: none;
    }

    .image-container {
      width: calc(100vw - 200px);
      height: 879px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;

      .expanded-image {
        max-height: 100%;
        max-width: calc(100% - 70px);
      }

      .close-button {
        width: 50px;
        height: 50px;
        background-color: #fbeb24;
        margin-left: 20px;
        cursor: pointer;
        position: relative;

        .close-button-img {
          height: 13px;
          width: 13px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .gallery-section-container {
    font-family: "Helvetica Neue";
    .gallery-title {
      font-size: 45px;
      line-height: 50px;
      text-transform: uppercase;
      margin-bottom: 50px;
      text-align: center;
    }

    .photos-grid {
      display: grid;
      justify-content: center;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, 155px);
      margin-bottom: 35px;

      .element-photo-grid {
        position: relative;
        width: 155px;
        height: 155px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .expand-button {
          width: 45px;
          height: 45px;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;

          .expand-photo-button-img {
            height: 15px;
            width: 15px;
            right: 0;
            top: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
    }

    .gallery-load-more-button-container {
      text-align: -webkit-center;
      margin-bottom: 70px;
    }
  }

  .dark-background-popup {
    position: fixed;
    top: 0;
    z-index: 3333;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    margin-left: -20px;

    .expanded-image-buttons {
      display: none;
    }
    .expanded-image-buttons-mobile {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      width: 100%;
      .button-expanded-image {
        width: 50px;
        height: 50px;
        background-color: #fbeb24;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;

        img {
          height: 10px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .image-container {
      width: calc(100vw - 40px);
      height: auto;
      padding-top: 20px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;

      .expanded-image {
        max-height: 320px;
        object-fit: contain;
        max-width: 100%;
      }

      .close-button {
        width: 32px;
        height: 32px;
        background-color: #fbeb24;
        cursor: pointer;
        position: relative;
        margin-bottom: 100px;
        align-self: flex-end;

        .close-button-img {
          height: 8px;
          width: 8px;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}
