@media only screen and (min-width: 1024px) {
  .competitions-container-3 {
    font-family: "Montserrat Bold";
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 132px;
    .competitions-title {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 95px;
    }

    .competitions-list {
      display: grid;
      justify-content: center;
      // grid-gap: 25px;
      // grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, 415px);

      .competition-card {
        height: 400px;
        width: 415px;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        padding-top: 10px;
        margin-bottom: 65px;
        border: 2px solid;

        .competition-name {
          font-size: 35px;
          line-height: 60px;
          margin-top: 15px;
          max-height: 114px;
          overflow: hidden;
          text-align: center;
        }

        .competition-group {
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 20px;
          padding-bottom: 10px;
        }

        .competition-coach {
          display: flex;
          justify-content: center;
          text-align: center;
          .avatar {
            vertical-align: middle;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 20px;
            object-fit: cover;
          }

          .coach {
            justify-self: center;
            font-size: 20px;
            font-weight: 800;
          }

          .coach-name {
            font-size: 16px;
          }
        }

        .competition-age {
          font-size: 50px;
          line-height: 38px;
        }
        .competition-more-button {
          position: absolute;
          width: 100%;
          bottom: 0;
        }
        .competition-button {
          font-family: "Montserrat Bold";
          font-size: 25px;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-radius: 0 0 25px 25px;
          width: 100%;
        }
      }
    }
  }

  .competition-page-container-3 {
    font-family: "Montserrat Bold";
    .competition-name {
      font-size: 100px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 95px;
    }

    .comp-option-picker {
      display: flex;
      justify-content: center;
      height: 90px;
      margin-bottom: 100px;
    }
    .comp-option-picker-mobile {
      display: none;
    }
  }

  .competition-results-container-3 {
    font-family: "Montserrat Bold";
    .round-container {
      .round-name {
        font-size: 50px;
        font-weight: 700;
        text-align: center;
      }
      .round-date {
        font-size: 25px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        .round-date-img {
          margin-right: 15px;
        }
      }

      .matches-container {
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 100px;

        .match-row {
          box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
          display: flex;
          justify-content: center;
          height: 100px;
          margin-bottom: 20px;

          .match-team-home {
            padding-right: 100px;
            display: flex;
            align-items: center;
            width: 40%;
            justify-content: flex-end;

            .team-name {
              font-size: 20px;
              font-weight: 800;
              text-transform: uppercase;
            }

            .team-logo {
              width: 50px;
              margin-left: 30px;
              img {
                width: 100%;
              }
            }
          }
          .match-team-away {
            padding-left: 100px;
            display: flex;
            align-items: center;
            width: 40%;
            justify-content: flex-start;

            .team-name {
              font-size: 20px;
              font-weight: 800;
              text-transform: uppercase;
            }

            .team-logo {
              width: 50px;
              margin-right: 30px;

              img {
                width: 100%;
              }
            }
          }

          .match-score {
            display: flex;
            align-items: center;

            .number {
              font-size: 45px;
              line-height: 55px;
            }
            .points {
              font-size: 35px;
              line-height: 55px;
              color: #d9d9d9;
              padding-left: 20px;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }

  .competition-ranking-container-3 {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 150px;
    .ranking-table-titles {
      display: flex;
      align-items: center;
      color: #bfbfbf;
      font-size: 16px;
      margin-bottom: 15px;

      .position {
        text-align: center;
        width: 10%;
      }

      .team-name {
        width: 27%;
      }

      .team-stat {
        text-align: center;
        width: 10%;
      }

      .team-shape {
        width: 14%;
        text-align: center;
      }

      .team-details {
        display: none;
      }
    }

    .teams-list {
      .team-row {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: black;
        height: 100px;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
        background-color: white;
        margin-bottom: 20px;

        .position {
          text-align: center;
          font-size: 45px;
          line-height: 55px;
          width: 10%;
        }

        .team-name {
          width: 27%;
          .name {
            width: min-content;
            position: relative;
            white-space: nowrap;

            .own-team-bar {
              width: 60px;
              height: 10px;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -35px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        .team-stat {
          text-align: center;
          width: 10%;
        }

        .team-shape {
          width: 14%;
          text-align: center;
          justify-content: center;
        }

        .team-details {
          display: none;
        }
      }
      .team-row-expanded-mobile {
        display: none;
      }
    }
  }

  .green-dot {
    height: 15px;
    width: 15px;
    background-color: #00ff00;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }

  .red-dot {
    height: 15px;
    width: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }

  .yellow-dot {
    height: 15px;
    width: 15px;
    background-color: #fbeb24;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }

  .yellow-bar {
    height: 10px;
    background-color: #fbeb24;
    width: 60px;
    position: absolute;
    bottom: -34px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .competitions-container-3 {
    font-family: "Montserrat Bold";
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 55px;
    .competitions-title {
      font-size: 45px;
      text-align: center;
      font-weight: 700;
      padding-bottom: 40px;
    }

    .competitions-list {
      display: grid;
      justify-content: center;
      grid-gap: 25px;
      grid-template-columns: repeat(1, 1fr);
      .competition-card {
        width: 100%;
        height: 300px;
        padding-top: 20px;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        margin-bottom: 45px;
        border: 2px solid;

        .competition-name {
          font-size: 24px;
          line-height: 40px;
          height: 80px;
          text-align: center;
        }
        .competition-group {
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 15px;
          padding-bottom: 5px;
        }

        .competition-coach {
          text-align: center;
          display: flex;
          justify-content: center;
          .avatar {
            vertical-align: middle;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin-right: 20px;
            object-fit: cover;
          }

          .coach {
            font-size: 15px;
          }

          .coach-name {
            font-size: 12px;
          }
        }

        .competition-age {
          font-size: 35px;
          line-height: 18.18px;
        }
        .competition-more-button {
          position: absolute;
          width: 100%;
          bottom: 0;

          .competition-button {
            font-family: "Montserrat Bold";
            font-size: 20px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            border-radius: 0 0 25px 25px;
            width: 100%;
          }
        }
      }
    }
  }

  .competition-page-container-3 {
    font-family: "Helvetica Neue";
    .competition-name {
      font-size: 45px;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .comp-option-picker {
      display: none;
    }
    .comp-option-picker-mobile {
      text-align: center;
      margin-bottom: 40px;

      .menu-mobile-dropdown {
        height: 50px;
        width: 100%;
        padding-left: 30px;
        font-size: 10px;
        background-color: black;
        color: #fbeb24;
      }
    }
  }

  .competition-results-container-3 {
    font-family: "Helvetica Neue";
    .round-container {
      .round-name {
        font-size: 35px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      .round-date {
        font-size: 15px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;

        .round-date-img {
          margin-right: 15px;
          height: 15px;
          width: 15px;
        }
      }

      .matches-container {
        margin-bottom: 40px;

        .match-row {
          box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
          // background-color: white;
          display: flex;
          justify-content: center;
          height: 90px;
          margin-bottom: 15px;

          .match-team-home {
            padding-right: 20px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            width: 40%;

            .team-name {
              font-size: 15px;
              line-height: 15px;
              font-weight: 800;
              text-transform: uppercase;
              text-align: center;
            }

            .team-logo {
              height: 45px;
              margin-bottom: 10px;
              img {
                height: 100%;
              }
            }
          }
          .match-team-away {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;

            .team-name {
              font-size: 15px;
              line-height: 15px;
              font-weight: 800;
              text-transform: uppercase;
              text-align: center;
            }

            .team-logo {
              height: 45px;
              margin-bottom: 10px;
              img {
                height: 100%;
              }
            }
          }

          .match-score {
            display: flex;
            align-items: center;

            .number {
              font-size: 34px;
              line-height: 41.38px;
            }
            .points {
              font-size: 34px;
              line-height: 41.38px;
              color: #d9d9d9;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  .competition-ranking-container-3 {
    margin-bottom: 150px;
    .ranking-table-titles {
      display: flex;
      align-items: center;
      color: #bfbfbf;
      font-size: 12px;
      margin-bottom: 10px;

      .position {
        text-align: center;
        width: 25%;
      }

      .team-name {
        width: 50%;
      }

      .team-stat {
        display: none;
      }

      .team-shape {
        display: none;
      }

      .team-details {
        width: 25%;
        text-align: center;
      }
    }

    .teams-list {
      .team-row {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: black;
        height: 85px;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.05);
        background-color: white;
        margin-bottom: 10px;

        .position {
          text-align: center;
          font-size: 34px;
          line-height: 41.38px;
          width: 25%;
        }

        .team-name {
          width: 50%;
          .name {
            width: min-content;
            position: relative;
            white-space: nowrap;

            .own-team-bar {
              width: 45px;
              height: 7px;
              background-color: #fbeb24;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -31px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        .team-stat {
          display: none;
        }

        .team-shape {
          display: none;
        }

        .team-details {
          width: 25%;
          text-align: center;
          img {
            height: 8px;
          }
        }
      }
      .team-row-expanded-mobile {
        height: 160px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: solid 1px #f2f2f2;
        padding-top: 35px;
        padding-left: 35px;
        padding-right: 35px;

        .stat-box {
          min-width: 70px;
          padding-left: 15px;
          padding-right: 15px;
          height: 30px;

          .stat-name {
            font-size: 12px;
            color: #bfbfbf;
            text-align: center;
          }
          .stat-value {
            font-size: 15px;
            text-align: center;
          }
        }
      }
    }
  }

  .green-dot {
    height: 10px;
    width: 10px;
    background-color: #00ff00;
    border-radius: 50%;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .red-dot {
    height: 10px;
    width: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .yellow-dot {
    height: 10px;
    width: 10px;
    background-color: #fbeb24;
    border-radius: 50%;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .border-right {
    border-right: solid 1px #f2f2f2;
  }

  .border-left {
    border-left: solid 1px #f2f2f2;
  }

  .mobile-restricted-bullets {
    display: none;
  }
}
