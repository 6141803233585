$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: $sub-color;
}

.group-3 {
  position: relative;

  .form-input-3 {
    background: none;
    color: #ffffff;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 25px 0;

    &:focus {
      outline: none;
      // border-bottom: 1px solid #fbeb24;
    }

    &:focus ~ .form-input-label-3 {
      @include shrinkLabel();
    }
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 1200px) {
  .half-width {
    width: 45%;
  }

  .full-width {
    width: 100%;
  }

  .form-input-label-3 {
    color: $sub-color;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }

  .group-3 {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .half-width {
    width: 100%;
  }
  .full-width {
    width: 100%;
  }

  .form-input-label-3 {
    color: $sub-color;
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }

  .group-3 {
    margin-bottom: 20px;

    .form-input-3 {
      padding-top: 5px !important;
      padding-bottom: 0 !important;

      margin-bottom: 10px;
    }
  }
  .no-margin-bottom {
    // margin-bottom: 0;
  }
}
